import { MessageId, LiveGameReactionId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function liveGameReaction__client__deleteLiveGameReaction(p: { liveGameReactionId: LiveGameReactionId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const entry = await h.LiveGameReaction.getDoc(p.liveGameReactionId);

  if (!entry) {
    return;
  }

  const nowMS = Date.now();

  await h.LiveGameReaction.update({
    id: p.liveGameReactionId,
    doc: {
      updatedAtMS: nowMS,
      deletedAtMS: nowMS,
      imageUri: h._MagicDeleteValue,
      videoUri: h._MagicDeleteValue,
      videoThumbnailUri: h._MagicDeleteValue
    }
  });
}

// i18n certified - complete
