import {
  EMOJIS,
  LiveGameReactionId,
  SoccerGameEventId,
  LiveGame__Reaction__SGE__Reactions,
  AccountId
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { MagicDeleteString } from "../../constants";

export async function liveGameReaction__client__sgeUpdateReaction(p: {
  liveGameSGEReaction: LiveGame__Reaction__SGE__Reactions;
  reaction: EMOJIS;
  state: boolean;
  accountId: AccountId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const { reactions, ...rest } = p.liveGameSGEReaction;
  const newLiveGameReaction = {
    ...rest,
    reactions: {
      ...reactions,
      [p.accountId]: {
        ...(reactions[p.accountId] || {}),
        [p.reaction]: p.state === true ? true : h._MagicDeleteValue
      }
    }
  };

  await h.LiveGameReaction.update({
    id: p.liveGameSGEReaction.id,
    doc: newLiveGameReaction
  });
}

// i18n certified - complete
