import { EMOJIS, LiveGameReactionId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function liveGameReaction__client__setEmojiReaction(p: {
  liveGameReactionId: LiveGameReactionId;
  reaction: EMOJIS;
  accountId: string;
  state: boolean;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.LiveGameReaction.update({
    id: p.liveGameReactionId,
    doc: { reactions: { [p.accountId]: { [p.reaction]: p.state ? true : h._MagicDeleteValue } } }
  });
}

// i18n certified - complete
