import { getUniversalHelpers } from "../../helpers";

const IMAGE_DIRECTORY = "timeline_uploads/";

export async function liveGameReaction__client__uploadImage(p: { name: string; blob: any }) {
  const { app } = getUniversalHelpers();
  return app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + p.name)
    .put(p.blob);
}

// i18n certified - complete
