import { getUniversalHelpers } from "../../helpers";
import { LiveGame__Reaction__Custom, LiveGame__Reaction__SGE, LiveGameReaction } from "@ollie-sports/models";
import { DistributiveOmit } from "../..";

export async function liveGameReaction__client__addLiveGameReaction(p: {
  liveGameReaction: DistributiveOmit<LiveGameReaction, "id" | "createdAtMS">;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let entryId = h.LiveGameReaction.generateId();

  await h.LiveGameReaction.add({ doc: { ...p.liveGameReaction, ...{ id: entryId, createdAtMS: Date.now() } } });
}

// i18n certified - complete
