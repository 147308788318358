import { SoccerGameEvent, SoccerGameId, LiveGameCustomEvent, LiveGameReaction } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";
import { SoccerLiveGameTimelineEvent } from "../../soccer-logic";

export function liveGameReaction__client__getSubscription(p: {
  soccerGameId: SoccerGameId;
}): BifrostSubscription<LiveGameReaction[] | undefined> {
  const disposeFns: any[] = [];
  const instance = createBifrostSubscription<LiveGameReaction[] | undefined>({
    dispose: () => disposeFns.forEach(fn => fn())
  });
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const sub = h.LiveGameReaction.querySubscription({
    where: [{ soccerGameId: ["==", p.soccerGameId] }]
  }).subscribe(
    a => {
      instance.nextData(a.docs as LiveGameReaction[]);
    },
    e => {
      instance.nextError(e);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}

// i18n certified - complete
