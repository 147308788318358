import { MessageId, LiveGameReactionId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function liveGameReaction__client__editLiveGameReaction(p: {
  liveGameReactionId: LiveGameReactionId;
  newText: string;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const entry = await h.LiveGameReaction.getDoc(p.liveGameReactionId);

  if (!entry) {
    return;
  }

  await h.LiveGameReaction.update({ id: p.liveGameReactionId, doc: { text: p.newText, updatedAtMS: Date.now() } });
}

// i18n certified - complete
